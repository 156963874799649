
import React from 'react';
import { MessageCircle, Brain, Clock } from 'lucide-react';

const Website = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <header className="bg-white shadow-sm">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex justify-between items-center">
            <div className="text-2xl font-bold text-blue-600">KD Transformation</div>
            <a href="/sitemap.xml" className="text-sm text-gray-500 hover:text-gray-700" target="_blank" rel="noopener noreferrer">Sitemap</a>
          </div>
        </nav>
      </header>
      
      <main>
        <section className="py-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
          <div className="text-center">
            <h1 className="text-4xl sm:text-5xl font-bold text-gray-900 mb-8">
              AI-Powered Solutions for Everyone
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-12">
              In a world where technological advancement often benefits the few, we're changing the paradigm. Through AI-powered personal assistance, we're democratizing access to tools that give you back your most precious resource - time.
            </p>
          </div>

          <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              icon={<MessageCircle className="h-12 w-12 text-blue-500" />}
              title="AI-First Solutions"
              description="We build intelligent solutions that learn and adapt to your unique needs, making technology work for you."
            />
            <FeatureCard
              icon={<Brain className="h-12 w-12 text-blue-500" />}
              title="Smart Automation"
              description="Automate repetitive tasks and workflows with AI that understands context and makes intelligent decisions."
            />
            <FeatureCard
              icon={<Clock className="h-12 w-12 text-blue-500" />}
              title="Time Empowerment"
              description="Reclaim your time and focus on what truly matters to you, while we handle the rest with intelligent automation."
            />
          </div>
        </section>

        <section className="py-16 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                Our Vision for Tomorrow
              </h2>
              <p className="text-lg text-gray-600">
                We're not just building better customer service; we're creating digital allies that understand, adapt, and grow with you. Our mission is to ensure everyone has their own dedicated AI partner, empowering you to pursue what matters most.
              </p>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-900 text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <p>&copy; {new Date().getFullYear()} KD Transformation. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
    <div className="flex justify-center mb-4">
      {icon}
    </div>
    <h2 className="text-xl font-semibold text-gray-900 text-center mb-4">{title}</h2>
    <p className="text-gray-600 text-center">{description}</p>
  </div>
);

export default Website;
